import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer.component';
import Header from './components/header/Header.component';
import Home from './pages/home/Home.page';
import QuickLinks from './pages/quickLinks/QuickLinks.page';

const App = () => (
  <Router>
    <div className="app">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/quick_links" element={<QuickLinks />} />
        
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
