import './TitleHeader.style.scss';

const TitleHeader = ({ firstTitlePart, secondTitlePart, primaryCLR, secondaryCLR, borderClR, shortDescription }) => {
    return (
        <div className="hero-container">
            <div 
                className="hero-title" 
                style={{
                    border: `3px dotted ${borderClR}`, 
                    color: primaryCLR 
                }}
            >
                {firstTitlePart} <span style={{ color: secondaryCLR }}>{secondTitlePart}</span>
            </div>
            <div className="hero-slogan">{shortDescription}</div>
        </div>
    );
};

export default TitleHeader;
